import {action, makeObservable, observable, runInAction} from 'mobx';
import BaseStore from "./baseStore";
import taskTrackerApi from "../api/taskTrackerApi";
import {createContext, useContext} from "react";
import {camelizeKeys, decamelize, decamelizeKeys} from "humps";
import _ from "lodash";
import {
    convertDateTimeWithoutTimeZone,
} from "../utils/mainUtils";

class TimerStore extends BaseStore {
    startTime = null;
    timer = 0;
    intervalId = null;
    todayRecords = [];
    totalTimeToday = '';
    totalTimeWeek = '';
    employeeName = '';

    constructor() {
        super();
        this.api = taskTrackerApi;
        makeObservable(this, {
            todayRecords: observable,
            totalTimeToday: observable,
            totalTimeWeek: observable,
            startTime: observable,
            timer: observable,
            intervalId: observable,
            start: action,
            stop: action,
            reset: action,
        });

        this.initializeTimer()
    }

    initializeTimer() {
        let inDate = localStorage.getItem('inDate') ?? false
        if (inDate) {
            this.intervalId = setInterval(() => {
                this.timer = Date.now() - inDate;
            }, 1000);
        } else {
            this.reset();
        }
    }

    loadData(method = '') {
        if (method && typeof this[method] === 'function') {
            this[method]();
        } else {
            super.loadData(this.api);
        }
    }

    setSearchTerm(search) {
        runInAction(() => {
            this.employeeName = search;
        });
        //  this.getAllUsersTasksTrackingListToday();
    }

    async getTasksTrackingListToday() {
        try {
            runInAction(() => {
                this.loading = true;
                this.isFirstLoadPage = false;
            });

            const queryParams = {
                sort_by: this.sortField ? decamelize(this.sortField) : 'id',
                sort_dir: this.sortOrder,
                pagination_size: 999999999,
                ..._.omitBy(this.filters, value => value === undefined || value === null || value === "")
            };

            const response = await this.api.getTasksTrackingListToday(queryParams);

            runInAction(() => {
                this.todayRecords = response?.data?.todayScans ?? [];
                this.totalTimeToday = response?.data?.totalWorkingTimeToday ?? '';
                this.totalTimeWeek = response?.data?.totalWorkingTimeWeek ?? '';
            });
        } catch (error) {
            if (error && error.code) {
                if (error?.code === 'ERR_CANCELED') {
                    return
                }
            }
            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
                return error?.response?.data?.message ?? error;
            });
            console.error("Loading expenses failed", error);
        } finally {
            this.loading = false;
            this.isFirstLoadPage = false;
        }
    }

    async getAllUsersTasksTrackingListToday() {
        try {
            runInAction(() => {
                this.loading = true;
                this.isFirstLoadPage = false;
            });

            const queryParams = {
                sort_by: this.sortField ? decamelize(this.sortField) : 'id',
                sort_dir: this.sortOrder,
                per_page: this.itemsPerPage,
                page: this.currentPage,
                employeeName: this.employeeName,
                ..._.omitBy(this.filters, value => value === undefined || value === null || value === "")
            };

            const response = await this.api.getAllUsersTasksTrackingListToday(queryParams);

            runInAction(() => {
                this.todayRecords = response?.data?.data ?? [];
                this.totalTimeToday = response?.data?.totalWorkingTimeToday ?? '';
                this.totalTimeWeek = response?.data?.totalWorkingTimeWeek ?? '';
                this.totalItems = response.data.total;
                this.itemsPerPage = response.data.perPage;
                this.lastPage = response.data.lastPage;
            });
        } catch (error) {
            if (error && error.code) {
                if (error?.code === 'ERR_CANCELED') {
                    return
                }
            }

            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
                return error?.response?.data?.message ?? error;
            });
            console.error("Loading expenses failed", error);
        } finally {
            this.loading = false;
            this.isFirstLoadPage = false;
        }
    }

    resetStore() {
        this.todayRecords = [];
        this.totalTimeToday = '';
        this.totalTimeWeek = '';
        this.employeeName = '';
        super.resetStore();
    }

    resetErrorsStore(withValidError = true) {
        super.resetErrorsStore(withValidError);
    }

    setSortFieldBreakManagement(field) {
        runInAction(() => {
            if (this.sortField === field) {
                this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortField = field;
                this.sortOrder = 'asc';
            }
        });

        this.getAllUsersTasksTrackingListToday();
    }

    addModel(model) {
        return super.addModel(this.api, model, false, 'getAllUsersTasksTrackingListToday');
    }

    editModel(id, model) {
        return super.editModel(this.api, id, model, false, 'getAllUsersTasksTrackingListToday');
    }

    async deleteBreakManagement(model) {
        try {
            runInAction(() => {
                this.loading = true;
                this.isLoading = true;
            });

            if (!model?.userId) {
                const error = {
                    response: {
                        data: {
                            message: "User ID is required"
                        }
                    }
                };
                throw error;
            }

            const copyModel = {
                'user_id': model.userId
            }

            const formatedModel = _.omitBy(copyModel, (value) => value === null || value === undefined || value === '');
            const decamelizedData = decamelizeKeys(formatedModel);

            let formData = new FormData();

            for (const key in decamelizedData) {
                if (key !== 'attachment_file' && key !== 'user_image') {
                    formData.append(key, decamelizedData[key]);
                }
            }

            await this.api.deleteBreakManagement(formData);
            this.getAllUsersTasksTrackingListToday();
        } catch (error) {
            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
                this.errorDetails = camelizeKeys(error.response.data.data ?? error);
                this.loading = false;
                this.isLoading = false;
                return error?.response?.data?.message ?? error;
            });
            console.error("Loading expenses failed", error);
            throw error;
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    deleteModel(id) {
        return super.deleteModel(this.api, id, 'getAllUsersTasksTrackingListToday');
    }

    async start(model = null, scanType = 'in', skipRequest = false) {
        try {
            this.startTime = Date.now() - this.timer;

            if (!skipRequest) {
                let closingRecordDateTime = '';

                if (model?.recordDate && model?.recordTime) {
                    closingRecordDateTime = convertDateTimeWithoutTimeZone(model?.recordDate, model?.recordTime)
                }

                const queryParams = {
                    scan_type: scanType,
                    closing_record_date_time: closingRecordDateTime ?? '',
                };

                const formatedQueryParams = _.omitBy(queryParams, _.isEmpty);
                const response = await this.api.scan(formatedQueryParams);
            }

            runInAction(() => {
                //     this.todayRecords = response?.data?.data?.todayRecords ?? response?.data?.todayRecords;
                //     this.totalTimeToday = response?.data?.data?.totalTimeToday ?? response?.data?.totalTimeToday;
                //    this.totalTimeWeek = response?.data?.data?.totalTimeWeek ?? response?.data?.totalTimeWeek;
            });

            if (scanType === 'out') {
                this.reset();
                clearInterval(this.intervalId);
                this.intervalId = null;
                localStorage.removeItem('inDate');
            } else {
                runInAction(() => {
                    localStorage.setItem('inDate', this.startTime);
                });

                this.intervalId = setInterval(() => {
                    this.timer = Date.now() - this.startTime;
                }, 1000);
            }
        } catch (error) {
            if (error && error.code) {
                if (error?.code === 'ERR_CANCELED') {
                    return
                }
            }

            console.error("start tracker failed", error);
            throw error;
        } finally {
        }
    }

    stop() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
        this.intervalId = null
    }

    reset() {
        this.stop();
        this.timer = 0;
        this.startTime = null;
    }

    setFilters(filters) {
        runInAction(() => {
            this.filters = filters;
        });
        //   this.getAllUsersTasksTrackingListToday();
    }

    get formattedTime() {
        const totalSeconds = Math.floor(this.timer / 1000);
        const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
        const seconds = String(totalSeconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }

    setBreakManagementCurrentPage(page) {
        runInAction(() => {
            this.currentPage = page;
        });
        this.getAllUsersTasksTrackingListToday();
    }
}

const timerStoreContext = createContext(new TimerStore());
export const useTimerStore = () => useContext(timerStoreContext);

export default timerStoreContext;
