import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Spinner, Form } from "react-bootstrap";
import Modal from "../common/Modal";
import { convertTimeToHIS } from "../../utils/mainUtils";
import axios from "axios";

const EditDepartmentTurn = observer(({ show, onClose, onSave, turnForEdit }) => {
    const [modelData, setModelData] = useState({ startTime: "", endTime: "" });
    const [errors, setErrors] = useState({ startTime: "", endTime: "" });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (turnForEdit) {
            setModelData({
                startTime: turnForEdit.start_time ? turnForEdit.start_time.slice(0, 5) : "",
                endTime: turnForEdit.end_time ? turnForEdit.end_time.slice(0, 5) : "",
            });
        }
    }, [turnForEdit]);

    const handleTimeChange = (e) => {
        const { name, value } = e.target;
        setModelData((prevData) => ({ ...prevData, [name]: value }));
    };

    const validateForm = () => {
        let isValid = true;
        let newErrors = { startTime: "", endTime: "" };

        if (!modelData.startTime) {
            newErrors.startTime = "Start time is required";
            isValid = false;
        }
        if (!modelData.endTime) {
            newErrors.endTime = "End time is required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValid = await validateForm();
        if (!isValid) return;

        try {
            setIsSubmitting(true);

            const payload = {
                start_time: modelData.startTime,
                end_time: modelData.endTime,
                starts: 1
            };

            const token = localStorage.getItem('token');
            const headers = token
                ? { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }
                : { "Content-Type": "application/json" };

            console.log(token);

            const response = await axios.put(
                `https://tmstage.indacotms.com/backend/public/api/turns-department/${turnForEdit.id}`,
                payload,
                { headers }
            );

            console.log(response.data);
            onSave();
        } catch (error) {
            console.error(error);
            setErrors(prev => ({ ...prev, startTime: "Failed to update turn." }));
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <Modal
            show={show}
            onClose={onClose}
            title="Edit Turn"
        >
            <Form>
                <Form.Group controlId="formrecordTimeStart">
                    <Form.Label className="label-in-modal">Start</Form.Label>
                    <Form.Control
                        className="select-in-modal"
                        type="time"
                        name="startTime"
                        value={modelData.startTime ? convertTimeToHIS(modelData.startTime) : ""}
                        onChange={handleTimeChange}
                        required
                        isInvalid={!!errors.startTime}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.startTime}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formrecordTimeEnd">
                    <Form.Label className="label-in-modal">End</Form.Label>
                    <Form.Control
                        className="select-in-modal"
                        type="time"
                        name="endTime"
                        value={modelData.endTime ? convertTimeToHIS(modelData.endTime) : ""}
                        onChange={handleTimeChange}
                        required
                        isInvalid={!!errors.endTime}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.endTime}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
            <Button
                className="button-save mt-2"
                onClick={handleSubmit}
                disabled={isSubmitting}
            >
                {isSubmitting ? <Spinner as="span" animation="border" size="sm" /> : "Save"}
            </Button>
        </Modal>
    );
});

export default EditDepartmentTurn;
