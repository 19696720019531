import React, {useEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form, Table} from 'react-bootstrap';
import {useConfigStore} from "../../stores/configStoreContext";
import "react-datepicker/dist/react-datepicker.css";
import Loader from '../common/Loader';
import {useUsersStore} from "../../stores/usersStore";
import {authStore} from "../../stores/authStore";
import {useTimerStore} from "../../stores/timerStore";
import FlashMessage from "../common/FlashMessage";
import {
    convertToTimeWithOffset,
    removeSecondsFromHMS
} from '../../utils/mainUtils';
import deleteIcon from "../../assets/images/icons/actions/delete.svg";
import editIcon from "../../assets/images/icons/actions/edit.svg";
import CustomPagination from "../common/CustomPagination";
import BreakManagementAdd from "./BreakManagementAdd";
import {Link} from "react-router-dom";
import sortIcon from "../../assets/images/icons/sort-icon.svg";
import CustomSelect from "../common/CustomSelect";
import inPlusIcon from "../../assets/images/icons/plus-in-button-icon.svg";
import BreakManagementDelete from "./BreakManagementDelete";

const BreakManagementList = observer(() => {
    const store = useTimerStore();
    const configStore = useConfigStore();
    const [filters, setFilters] = useState({});
    const [selectedModel, setSelectedModel] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const firstRender = useRef(true);
    const usersStore = useUsersStore();
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState('success');
    const [selectedFilteredUsers, setFilteredSelectedUsers] = useState('');

    useEffect(() => {
        if (firstRender.current) {
            resetStore();
            store.loadData('getAllUsersTasksTrackingListToday')
            return;
        }
    }, []);

    const userOptions = useMemo(() =>
        usersStore.users.map(item => ({
            value: item.id,
            label: item.firstName,
        })), [usersStore.users]);

    const handleSearchChange = (event = '') => {
        const value = event?.target?.value ?? '';

        setSearchTerm(value);
        store.setSearchTerm(value);
        store.loadData('getAllUsersTasksTrackingListToday')
    }

    const resetStore = () => {
        store.resetSort();
        setFilters({});
        setFilteredSelectedUsers('')
        store.resetStore();
    }

    const handleSort = (field) => {
        store.setSortFieldBreakManagement(field);
    };

    const handleFilterChange = (event, field) => {
        const {value} = event.target;
        const newFilters = {
            ...filters,
            [field]: value,
        };
        setFilters(newFilters);
        store.setFilters(newFilters);
        store.loadData('getAllUsersTasksTrackingListToday')
    };

    const handleItemsPerPageChange = (pageSize) => {
        store.setItemsPerPage(pageSize);
        store.setBreakManagementCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        store.setBreakManagementCurrentPage(pageNumber);
    };

    const handleDelete = async () => {
        if (selectedModel) {
            setIsSubmitting(true);
            try {
                await store.deleteBreakManagement(selectedModel);
                setShowAddModal(false);
                setShowEditModal(false);
                setMessage('Success!');
                setVariant('success');
            } catch (error) {
                setMessage(`Error!: ${store?.errors}`);
                setVariant('danger');
                console.error('Failed to delete model:', error);
            } finally {
                setIsSubmitting(false);
                setShowDeleteModal(false);
                setSelectedModel(null);
            }
        }
    };

    const handleAddClick = () => {
        setSelectedModel(null);
        setShowAddModal(true);
    };


    const handleAdd = async (modelData) => {
        setIsSubmitting(true);
        try {
            await store.addModel(modelData);
            setShowAddModal(false);
            setShowEditModal(false);
            setMessage('Success!');
            setVariant('success');
        } catch (error) {
            setMessage(`Error!: ${store.errors}`);
            setVariant('danger');
            throw error;
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleEdit = async (id, modelData) => {
        setIsSubmitting(true);
        try {
            await store.editModel(id, modelData);
            setShowAddModal(false);
            setShowEditModal(false);
            setMessage('Success!');
            setVariant('success');
        } catch (error) {
            setMessage(`Error!: ${store.errors}`);
            setVariant('danger');
            throw error;
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDetailClick = (model) => {
        if (!store.loading) {
            setSelectedModel(model);
            setShowDetailModal(true);
        }
    };

    const handleDeleteClick = (model) => {
        if (!store.loading) {
            setSelectedModel(model);
            setShowDeleteModal(true);
        }
    };

    const handleEditClick = (model) => {
        if (!store.loading) {
            setSelectedModel(model);
            setShowEditModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowAddModal(false);
        setShowDetailModal(false);
        setShowEditModal(false);
        setShowDeleteModal(false);
        setSelectedModel(null);
    };

    const handleClearFilterClick = () => {
        const clearedFilters = Object.keys(filters).reduce((acc, key) => {
            acc[key] = '';
            return acc;
        }, {});
        store.resetSort();
        setFilters(clearedFilters);
        setFilteredSelectedUsers('')
        store.setFilters(clearedFilters);
        setSearchTerm('');
        store.setSearchTerm('');
        store.loadData('getAllUsersTasksTrackingListToday')
    }

    const resetErrorMessage = () => {
        setMessage(null)
        store.resetErrorsStore(false);
        usersStore.resetErrorsStore(false);
    }

    return (
        <>
            <div className={'wrap-title-page'}>
                <h2 className={'page-title mb-3'}>Break Management</h2>
            </div>

            <div className={'wrap-table-tools align-items-center'}>
                <div className={'search-block'}>
                    {<div className={'me-3'}>
                        <div>
                            <Form.Control
                                className={'filter-up-table'}
                                type="text"
                                placeholder="Search here"
                                value={searchTerm}
                                onChange={(e) => handleSearchChange(e)}
                            />
                        </div>
                    </div>}
                    <div className={'custom-add-label'}>
                        <Link className={'custom-add-label me-5'} onClick={handleClearFilterClick}>Clear
                            filters</Link>
                    </div>
                </div>
                <div className={'button-block'}>
                    <div>
                        <Button className={'button-custom button-blue'} onClick={handleAddClick}><img
                            src={inPlusIcon}/> Insert scan</Button>

                    </div>
                </div>
            </div>

            <div className={'relative'}>
                <div>
                    {store.loading === true ? (<Loader/>) : ''}
                    <div className={'wrap-table'}>
                        <Table className={'list-table'} hover>
                            <thead className={'thead-table'}>
                            <tr className={'no-hover'}>
                                <th className={'sort-th'} onClick={() => handleSort('lastName')}>
                                    <div>Employee Name
                                        <img
                                            className={store.sortField == 'lastName' && store.sortOrder == 'asc' ?
                                                'inverse-image' :
                                                ''}
                                            src={sortIcon}
                                        />
                                    </div>
                                </th>

                                <th className={'sort-th'} onClick={() => handleSort('designation')}>
                                    <div>Job Title
                                        <img
                                            className={store.sortField == 'designation' && store.sortOrder == 'asc' ?
                                                'inverse-image' :
                                                ''}
                                            src={sortIcon}
                                        />
                                    </div>
                                </th>
                                <th className={'sort-th'} onClick={() => handleSort('firstInTime')}>
                                    <div>Started
                                        <img
                                            className={store.sortField == 'firstInTime' && store.sortOrder == 'asc' ?
                                                'inverse-image' :
                                                ''}
                                            src={sortIcon}
                                        />
                                    </div>
                                </th>
                                <th className={'sort-th'} onClick={() => handleSort('lastScanTime')}
                                    style={{borderRight: '1px solid #C3CFFF'}}>
                                    <div>Last scan
                                        <img
                                            className={store.sortField == 'lastScanTime' && store.sortOrder == 'asc' ?
                                                'inverse-image' :
                                                ''}
                                            src={sortIcon}
                                        />
                                    </div>
                                </th>
                                <th className={'sort-th'} onClick={() => handleSort('workingHours')}>
                                    <div>Total
                                        <img
                                            className={store.sortField == 'workingHours' && store.sortOrder == 'asc' ?
                                                'inverse-image' :
                                                ''}
                                            src={sortIcon}
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div></div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className={'table-head-tools'}>
                                <td>
                                    <CustomSelect options={userOptions}
                                                  store={store}
                                                  column={'userId'}
                                                  filters={filters}
                                                  selectedFilter={selectedFilteredUsers}
                                                  setSelectedFilter={setFilteredSelectedUsers}
                                                  setFilters={setFilters}
                                                  styleClass={'select-without-border'}
                                                  loadMethod={'getAllUsersTasksTrackingListToday'}
                                    />
                                </td>

                                <td>
                                    <Form.Select
                                        className={'filter-in-table'}
                                        aria-label="Default select example"
                                        value={filters['designationId']}
                                        onChange={(e) => handleFilterChange(e, 'designationId')}
                                    >
                                        <option value="">Job title</option>
                                        {Object.entries(configStore?.configData?.designations).map(([key, value]) => (
                                            <option key={key} value={key}>{value}</option>
                                        ))}
                                    </Form.Select>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                            </tr>

                            {store?.todayRecords?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.firstName + ' ' + item?.lastName}</td>
                                    <td>{item?.designation}</td>
                                    <td>{convertToTimeWithOffset(item?.firstInTime)}</td>
                                    <td style={{borderRight: '1px solid #C3CFFF'}}>{convertToTimeWithOffset(item?.lastScanTime)}</td>
                                    <td>{removeSecondsFromHMS(item?.workingHours)}</td>
                                    <td>
                                        <div className={'wrap-button-row d-flex no-wrap'}>
                                            <img src={deleteIcon} alt="Delete"
                                                 onClick={() => handleDeleteClick(item)}/>
                                            <img src={editIcon} alt="Edit"
                                                 onClick={() => handleEditClick(item)}/>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                    <CustomPagination
                        store={store}
                        handlePageChange={handlePageChange}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                    />
                </div>
            </div>
            <BreakManagementAdd
                data={selectedModel}
                show={showAddModal || showEditModal}
                onClose={handleCloseModal}
                onAdd={handleAdd}
                onEdit={handleEdit}
                isSubmitting={isSubmitting}
                store={store}
            />
            <BreakManagementDelete
                model={selectedModel}
                show={showDeleteModal}
                onClose={handleCloseModal}
                onDelete={handleDelete}
                isSubmitting={isSubmitting}
            />
            <FlashMessage message={message} variant={variant} onClose={() => resetErrorMessage()}/>
            {!message &&
                <FlashMessage message={store.errors || usersStore.errors} variant={'danger'}
                              onClose={() => resetErrorMessage()}/>
            }
        </>
    );
});

export default BreakManagementList;
