import {useEffect} from "react";
import DepartmentTurnsList from "../components/departments/DepartmentTurnsList";

const DepartmentTurnsPage = () => {
    useEffect(() => {
        document.title = 'Department Turns';
    }, []);


    return (
        <div className={'wrap-page'}>
            <DepartmentTurnsList />
        </div>
    )
}

export default DepartmentTurnsPage;
