import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Spinner, Table } from "react-bootstrap";
import axios from "axios";
import editIcon from "../../assets/images/icons/actions/edit.svg";
import deleteIcon from "../../assets/images/icons/actions/delete.svg";
import EditDepartmentTurn from "./EditDepartmentTurn";
import CreateDepartmentTurn from "./CreateDepartmentTurn";

const DepartmentTurnsList = observer(() => {
    const [turnsData, setTurnsData] = useState(null);
    const [openEditTurnModal, setOpenEditTurnModal] = useState(false);
    const [turnForEdit, setTurnForEdit] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [openCreateTurnModal, setOpenCreateTurnModal] = useState(false);

    const fetchTurnsData = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://tmstage.indacotms.com/backend/public/api/turns-department', {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching turns data:', error);
            return null;
        }
    };

    useEffect(() => {
        const getData = async () => {
            const response = await fetchTurnsData();
            setTurnsData(response.data);
        };
        getData();
    }, []);

    const handleDeleteItem = async (turnId) => {
        try {
            setDeleting(true);
            const token = localStorage.getItem('token');
            await axios.delete(`https://tmstage.indacotms.com/backend/public/api/turns-department/${turnId}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            });
            const updatedData = await fetchTurnsData();
            setTurnsData(updatedData?.data || []);
        } catch (error) {
            console.error('Error deleting turn:', error);
        } finally {
            setDeleting(false);
        }
    };

    return (
        <>
            <h2 className="mb-4">Department Turns</h2>
            <Table hover responsive striped bordered>
                <thead>
                <tr>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {turnsData && turnsData.length > 0 ? (
                    turnsData.map((turn) => (
                        <tr key={turn.id}>
                            <td>{turn.start_time}</td>
                            <td>{turn.end_time}</td>
                            <td>
                                <div className="d-flex align-items-center gap-2">
                                    <button
                                        className="btn btn-link p-0"
                                        onClick={() => {
                                            setTurnForEdit(turn);
                                            setOpenEditTurnModal(true);
                                        }}
                                        title="Edit"
                                    >
                                        <img src={editIcon} alt="Edit" />
                                    </button>
                                    <button
                                        className="btn btn-link p-0"
                                        onClick={() => handleDeleteItem(turn.id)}
                                        title="Delete"
                                    >
                                        <img src={deleteIcon} alt="Delete" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="3" className="text-center">
                            <Spinner variant="primary" />
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>

            <Button
                className="mt-2"
                onClick={() => setOpenCreateTurnModal(true)}
            >
                Add Turn
            </Button>

            <EditDepartmentTurn
                show={openEditTurnModal}
                turnForEdit={turnForEdit}
                onClose={() => {
                    setOpenEditTurnModal(false);
                    setTurnForEdit(null);
                }}
                onSave={async () => {
                    setOpenEditTurnModal(false);
                    const updatedData = await fetchTurnsData();
                    setTurnsData(updatedData?.data || []);
                }}
            />

            <CreateDepartmentTurn
                show={openCreateTurnModal}
                onClose={() => setOpenCreateTurnModal(false)}
                onSave={async () => {
                    setOpenCreateTurnModal(false);
                    const updatedData = await fetchTurnsData();
                    setTurnsData(updatedData?.data || []);
                }}
            />
        </>
    );
});

export default DepartmentTurnsList;
