import {useEffect} from "react";
import CompanyTurnsList from "../components/companies/CompanyTurnsList";

const CompanyTurnsPage = () => {
    useEffect(() => {
        document.title = 'Company Turns';
    }, []);


    return (
        <div className={'wrap-page'}>
            <CompanyTurnsList />
        </div>
    )
}

export default CompanyTurnsPage;
